import { Button } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import Settings from "../../../../settings";
import { useSelector } from "react-redux";
import {
	MapsHomeWork,
  Feedback,
	Person,
  PersonAdd,
	Receipt,
	QueryStats,
	AutoGraph,
	Savings,
	AddHomeWork,
	SpaceDashboard
} from "@mui/icons-material";
import { RootState } from "@/store";
import { useNavigationItemsContext } from "../../useNavigationItemsContext";
import { contraction_url, home_url } from "@/constants/i18n";
import DinamicServerNavigation from "@/components/ServerElements/DinamicServerNavigation";

export const UpLg = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { currentPath } = useNavigationItemsContext();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const newCustomer = useSelector((state: RootState) => state.auth.newCustomer);

	const navigateLogin = () => {
		if (Settings.features?.oidc) {
			navigate("/");
		} else {
			navigate(t("common:url.login"));
		}
	}

	const detailsButtons = () => {
		return <>
				<Button 
					variant="contained"
					onClick={() => navigate(t('common:url.contracts'))}
					startIcon={<MapsHomeWork />}
				>
					{t('common:text.contracts_title')}
				</Button>
				
				<Button 
					variant="contained"
					onClick={() => navigate(t('common:url.invoices'))}
					startIcon={<Receipt />}
				>
					{t('common:text.invoices_title')}
				</Button>
			</>
	}

	const openIdButtons = () => {
		return !newCustomer ?
			<>
				<Button 
					variant="contained"
					onClick={() => navigate(t('common:url.contracts'))}
					startIcon={<MapsHomeWork />}
				>
					{t('common:text.contracts_title')}
				</Button>
				
				<Button 
					variant="contained"
					onClick={() => navigate(t('common:url.invoices'))}
					startIcon={<Receipt />}
				>
					{t('common:text.invoices_title')}
				</Button>
			</>
		:
			<>
				<Button 
					variant="contained"
					onClick={() => navigate(t(contraction_url))}
					startIcon={<AddHomeWork />}
				>
					{t('common:text.contractation_button_title')}
				</Button>
			</>
	}

	return <>
		{Settings?.header?.variant === "horizontal" && <>
			{!isAuthenticated && <>
				{currentPath !== 'common:url.login' &&
					<Button 
						variant="contained"
						onClick={() => navigateLogin()}
						startIcon={<Person />}
					>
						{t('common:text.login_title')}
					</Button>	
				}

				{Settings.features?.signup &&
					currentPath !== 'common:url.activate' &&
					!Settings.features?.oidc &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.activate'))}
						startIcon={<PersonAdd />}
					>
						{t('common:text.login_view_button_activate')}
					</Button>	
				}

				{Settings.features?.unsignedContact && Settings.features?.contact &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.unsigned_contact'))}
					>
						{t('common:text.contact_title')}
					</Button>	
				}

				{Settings?.comparator?.enabled &&
					currentPath !== 'common:url.priceComparator' &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.priceComparator'))}
						startIcon={<Savings />}
					>
						{t('common:text.menu_price_comparator_title')}
					</Button>	
				}

				{Settings?.comparator?.enabled &&
					currentPath !== 'common:url.powerCalculator' &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.powerCalculator'))}
						startIcon={<AutoGraph />}
					>
						{t('common:text.menu_power_calculator_title')}
					</Button>	
				}
			</>}

			{isAuthenticated && <>
				{Settings?.features?.oidc && openIdButtons()}
				{!Settings?.features?.oidc && detailsButtons()}

				{Settings.features?.contact &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.contact'))}
						startIcon={<PersonAdd />}
					>
						{t('common:text.contact_title')}
					</Button>	
				}

				{Settings.features?.reclamATCMenu &&
					<Button 
						variant="contained"
						onClick={() => navigate(t('common:url.reclamations'))}
						startIcon={<Feedback />}
					>
						{t('common:text.reclamations_title')}
					</Button>	
				}

				{Settings.features?.contactAutoReads &&
					<Button 
						variant="contained"
						onClick={() => navigate(
							t('common:url.contact') + '?autolectures=true')
						}
						startIcon={<QueryStats />}
					>
						{t('common:text.send_readings_label')}
					</Button>	
				}

			</>}

			{Settings.features?.enableServerElements && 
				<DinamicServerNavigation 
					clickableComponent={({children, onClick}) => {
						return ( 
							<Button 
								variant="contained"
								startIcon={<SpaceDashboard />}
								onClick={onClick}
							>
								{children}
							</Button>	
						)
					}}
				/>
			}
		</>}
	</>
}